.Windows {
  pointer-events: none;
  position: fixed;
  margin: 0;
  padding: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.Windows .Window {
  pointer-events: all;
  position: fixed;
  z-index: 20;
}

.Windows .Window.focused {
  z-index: 21;
}
