.PrimesWallpaper {
    background: black url(./primes.jpg) no-repeat fixed center;
    background-size: cover;
    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
