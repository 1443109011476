.Wallpapers {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 0.25rem;
}

.Wallpapers img {
  width: 360px;
  border: 5px solid transparent;
  border-radius: 25px;
  flex: 1 1 auto;
  text-align: center;
  cursor: pointer;
  margin: 0.25rem;
}

.Wallpapers img:hover,
.Wallpapers img.selected {
  border: 5px solid #6a89cc;
}
