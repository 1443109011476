@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

html,
body,
#root {
  font-family: "Roboto", sans-serif;
  position: fixed;
  margin: 0;
  padding: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#root > div.Windows > div > div.handle > div:nth-child(1) > a:nth-child(2),
#root > div.Windows > div > div.handle > div:nth-child(1) > a:nth-child(3) {
  display: none;
}
