.Folder {
  position: absolute;
  margin: 0;
  padding: 2rem;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
}

.Folder .Item {
  width: 128px;
  height: 128px;
  padding: 1rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  text-align: center;
  color: white;
  z-index: 10;
  cursor: pointer;
}

.Folder .Item:hover,
.Folder .Item.focused {
  background-color: rgba(120, 224, 143, 0.8);
  border-radius: 10px;
  z-index: 11;
}

.Folder .Item svg {
  margin-bottom: 0.75rem;
}
